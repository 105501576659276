.modal--content{
    font-size: 0.899rem;
    font-weight: bold;
    text-align: center;
}

.modal--btn{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.logout-modal-btn{
    margin-top: 3rem !important;
}
.modal--btn--left button{
    background:#3fcc35;
    border: none;
    color: #fff;
    font-size: 1.099rem;
    padding: 10px;
    margin-right: 1rem;
    border-radius: 0.2rem;

}
.modal--btn--right button{
    background-color:#dc3545;
    border: none;
    color: #fff;
    font-size: 1.099rem;
    padding: 10px;
    margin-left: 1rem;
    border-radius: .2rem;

}

.modal--btn--right button i {
    margin-left: .5rem;
}
.modal--btn--left button i {
    margin-right: .5rem;
}
.logout--modal{
    padding: 4rem 2rem 2rem 2rem;
    width: 25% !important;
}
.logout_page_icons{
    font-size: 1rem !important;
    padding: 0 0.3rem;
}
@media only screen and (max-width: 1200px){
    .MuiModal-root .logout--modal{
  width: 85% !important;
  height: 20% !important;
  padding: 4rem 1rem !important;

}
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .MuiModal-root .logout--modal{
  width: 85% !important;
  height: 10% !important;
  padding: 4rem 1rem !important;

}
}
