.mobileChatPage{
    display: flex;
    flex-direction: column;
    position: relative;
    /* width: 100%; */
    height: 100%;
    /* position: relative; */
    /* bottom: 0px; */
    /* z-index: 103; */
    /* top: 0; */
    /* left: 0;
    right: 0; */
    background-color: #faf8ff;
    /* display: flex; 
    flex-direction: column;
    position: absolute;
    width: 90%;
    height: 96%;
    z-index: 2;
    top: 20px;
    background-color: #faf8ff; */
}
.header-wrapper{
    position: relative;
    /* top:0; */
    z-index: 1;
    width: inherit;
}
.error-footer-mobile{
   position: relative;
   top: -4.5rem;
}
.mainchat{
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    height: 100%;
    /* flex: 1; */
}
.mobile-mainchat{
    position: relative;
    /* margin-top: 4rem; */
    /* padding-top: 73px; */
    /* top: 73px; */
    /* bottom: 80px; */
}

.mobileChatPage .besttime-banner {
    background-color:var(--primary-color);
    color: white;

}

.mobileChatPage .sender .msg {
    margin-left: auto;
    background: #ffffff;
}

.mobileChatPage h5 {
    font-size: 1rem;
    word-break: break-word;
}

.mobileChatPage .msgtext {
    
    width: 100%;
}



.mobileChatPage .header{
    padding: 0 0.5rem;
    white-space: nowrap;
}
.mobileChatPage .header i{
    color: var(--primary-color);
}

.mobileChatPage .received .msg {
    margin-right: auto;
    background: var(--primary-color);
    color: white;
}

.mobileChatPage .footer{
    box-shadow: 0 4px 30px 5px rgb(0 0 0 / 16%);
    background-repeat: no-repeat;
    background-size: 108%;
    background-position: 50%;
    background-color: var(--primary-color);
     position: absolute;
    /* position: relative;*/
    bottom: 0;
    /* min-width: 100%; */
    /* display: flex;
    justify-content: center;
    flex: 1;
    width: 100%; */
    width: inherit;
}
.mobileChatPage .footer i{
color: white;
}
.mobile-footer{
    padding: 20px 0px !important;
}

.mobileChatPage .mobileFooterSend button{
   
    top: -100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


 /* image display popup css starts */
 .mobileimagePopup{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000070;


    /* border: 1px solid red; */
    color: black;
}
.mobileouterdiv{
    position: relative;
    /* border: 1px solid greenyellow; */
    padding-top:8px;
    padding-bottom:8px;
    
}
.mobileimageBox{
    /* border: 1px solid blue; */
    
}
.mobileimageBox img{
    /* border: 1px solid orangered; */
    display: block;
    max-width: 100%;
    max-height: 100%;
}
.mobileimageBox #mobiledownloadBtn{
    /* border: 1px solid red; */
    position: absolute;
    right: 5px;
    bottom: 10px;
}
.mobileimageBox #mobilecloseBtn{
    /* border: 1px solid red; */
    position: absolute;
    right: 5px;
    top: 10px;
}
.mobilepopupBtn{
    /* border: 1px solid orangered; */
    border-radius: 50%;
    text-decoration: none;
    font-size: 15px;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    color: white !important;
    background-color: var(--primary-color);
    display: flex;
    cursor: pointer;
}

#scrollableDiv{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    perspective: 1000;
    -webkit-perspective: 1000;
    scroll-behavior: auto;
    animation: redraw 1s linear infinite;
}
@keyframes redraw{
    0% {opacity: 1;}
    100% {opacity: .99;}
}
.mobileChatPage{
    position: relative;
}

.header-callbtn{
    background-color: var(--primary-color);
border: none;
border-radius: 50%;
width: 28px;
height: 28px;
color: #fff;
display: flex;
justify-content: center;
align-items: center;
margin-right: 0.5rem;
margin-top: 2px;
/* margin-right: 1rem; */
}


/* Loader */

.loader {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: rgba(255, 255, 255, 0.15);
    overflow: hidden;
  }
  .loader::after {
    content: '';
    width: 192px;
    height: 3px;
    background: var(--primary-color);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
  
  @keyframes animloader {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }
    
  

@media only screen and (min-width: 768px ) and (max-width: 1200px){
    .mobileimageBox img{
        /* border: 1px solid blue; */
        
        max-height:500px;
        /* height:calc(100vh-500px); */
    }

    .header-callbtn{
        background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 1rem; */
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .mobileimageBox img{
        /* border: 1px solid blue; */
        
        max-height:500px;
        /* height:calc(100vh-500px); */
    }
}
@media only screen and (max-width: 767px ){
    .mobileimageBox img{
        /* border: 1px solid blue; */
        max-height:500px;
        /* height:calc(100vh-500px); */
    }

    .header-callbtn{
        margin-top: 4px;
    }
}