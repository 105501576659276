.avatar-container{
    border-radius: 50%;
    /* margin-right: 1rem; */
    /* width: 4rem;
    height: 3.5rem; */
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    color: var(--primary);
    overflow: hidden;
    background-color: #faf8ff;
    /* border: 1px solid rgba(115,75,209,.301961);   */
}
.avatar-container svg, .avatar-container img{
    width: 100%;
    fill: var(--primary)
}
.avatar-container .wh-83, .avatar-container .wh-83{
    width: 83%;
}

.other-staff svg, .other-staff img{
    width: 100%;
    height: 100%;
}
.other-staff .wh-83, .other-staff .wh-83{
    width: 83%;
    height: 83%;
}
.unknown-icon-width, .emp-user img{
    width: 100%;
}
.avatar-border{
    border: 2px solid rgba(115,75,209,.301961)
}
 .MuiAvatar-circular{
    /* background-color: rgb(from var(--primary-color)) r g b / 0.5 !important; */
      font-size: 1rem;
      font-weight: bold;      
      border: 1px solid #734BD14D
}
.MuiAvatar-fallback{
    display: none !important;
}

.avatarBadge{
    position: relative;
}

.optBadge {
    position: absolute;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    min-width: 0.8rem;
    height: 0.8rem;
    z-index: 1;
}

.optedOutBadge {
    background: #e80303;
}

.optedInBadge {
    background: #4a9c4e;
}

.optedIntroBadge {
    background: #ffc400;
}

.optedDefaultBadge {
    background: #868686;
}