@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
.video-join{
    padding: 0 1rem;
    border-radius: 15px;
    border-color: transparent;
    background-color: var(--primary-color);
    color: white;
    height: 2rem;
    margin-right: 2.5rem;
    font-size: 1rem;
    margin-left: 0 !important;
}
.video-call-log-list-item:nth-child(4){
    width: 7%;
}
.video-calllogListItem-content{
    width:100%
}
.avatar-div{
    padding: 0 0 0 1rem;
}
.video-btn{
    padding: 0.5rem 1rem 0.5rem 0.8rem;
    border-radius: 23px;
    border-color: transparent;
    background-color: var(--primary-color);
    color: white;
    height: 1.5rem;
    width: 11rem;
    font-size: 1.2rem;
    /* margin-left: 83%; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
}
.font-video-meeting{
    color: white;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    margin-bottom: 0;

}
.videolist-body{
    height:100%;
    overflow-y:scroll;
}
.videolist-body>ul>li{
    text-decoration: none;
    padding: 1rem;
    /* padding-top: 0.1rem; */
}
.videolist-title {
    display: flex;
    justify-content: space-between;
    padding-left: 2.5rem;
    padding-right: 1.5rem;
    font-size: 1.099rem;
    font-weight: bold;
    text-align: start !important;
    color: var(--primary-color);
    align-items: center;
    padding-top: 1rem;
    position: sticky;
    top: 0;
    background: var(--secondary-color) !important;
    z-index: 1;
}
}
@media only screen and (max-width: 1200px)
{
    .video-btn{
        padding: 0.5rem 1rem 0.5rem 0.6rem;
        border-radius: 4rem;
        border-color: transparent;
        background-color: var(--primary-color);
        color: white;
        font-size: 1.2rem;
        /* margin-left: 83%; */
        cursor: pointer;
        width: 1.5rem;
        /* height: 2rem; */
        right: 1rem;
        /* left: rem; */
        position: fixed;
        display: flex;
        bottom: 4rem;
    }
    .font-video-meeting{
       display: none;
    }
    .DateTime{
        display: block;
        width: 100%;
        /* font-variant: small-caps; */
        font-size: 1.099rem;
        padding-left: 1rem;
    }
    .videolist-body>ul>li{
        text-decoration: none;
        /* padding: 1.5rem; */
        padding-top: 0.1rem;
    }
    .videolist-body{
        height:100%;
        overflow-y:scroll;
    }
    .video-join{
        padding: 0 1rem;
        border-radius: 15px;
        border-color: transparent;
        background-color: var(--primary-color);
        color: white;
        height: 2rem;
        /* margin-left: 7rem; */
        font-size: 1rem;
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse)
{
    .video-btn{
        padding: 0.5rem 1rem 0.5rem 0.6rem;
        border-radius: 4rem;
        border-color: transparent;
        background-color: var(--primary-color);
        color: white;
        font-size: 1.2rem;
        /* margin-left: 83%; */
        cursor: pointer;
        width: 1.5rem;
        /* height: 2rem; */
        right: 1rem;
        /* left: rem; */
        position: fixed;
        display: flex;
        bottom: 4rem;
    }
    .font-video-meeting{
       display: none;
    }
    .DateTime{
        display: block;
        width: 100%;
        /* font-variant: small-caps; */
        font-size: 1.099rem;
        padding-left: 1rem;
    }
    .videolist-body>ul>li{
        text-decoration: none;
        /* padding: 1.5rem; */
        padding-top: 0.1rem;
    }
    .videolist-body{
        height:100%;
        overflow-y:scroll;
    }
    .video-join{
        padding: 0 1rem;
        border-radius: 15px;
        border-color: transparent;
        background-color: var(--primary-color);
        color: white;
        height: 2rem;
        /* margin-left: 7rem; */
        font-size: 1rem;
    }
}


@media (min-width:320px) and  (max-width:1199px) {
    /* .video-join{
        margin-left: 6rem;
    } */
    

    .video-mobile-header{
        display: flex !important;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: #fff;
    }
    
    .header-title{
        flex: 1;
        border: none;
        font-size: 1rem;
        padding: 1rem;
        background: #fff;
    }
    
    .active-tab{
        color: #734BD1;
        font-weight: bold;
        border-bottom: 2.5px solid #734BD1 !important;
    }
    
    .inactive-tab{
        color: #828287;
        border-bottom: 1.5px solid #bbbbbf;
    }

    .meeting-list{
            gap: 1rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            background: #fff;
            padding-bottom: 3rem;
            /* overflow-x: scroll; */
        }
    .videoListScroll
    {
        background: var(--white);
    }
        .meeting-list-item{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 3px #00000040;
            border-radius: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 1rem;
            margin: 0.5rem;
            position: relative;
        }

        .meeting-date{
            font-size: 1rem;
            font-weight: bold;
            color: #595959;
            margin: 0 0 1rem 0;
        }
        .meeting-name{
            font-size: 1rem;
            font-weight: bold;
            color: #595959;
        }
        .meeting-time{
            font-size: 1rem;
            color: #595959;
            margin: 0 0 0.8rem 0;
        }
        .meeting-host{
            font-size: 0.8rem;
            color: #595959;
        }
        .meeting-host span {
            font-weight: bold;
            color: #734BD1;
        }
        .meeting-icon{
            position: absolute;
            top: 2rem;
            right: 1rem;
        }
        .meeting-details{
        }

        .video-log-header{
            display: none !important;
        }

        .videolist-body{
            height:100%;
            overflow-y:scroll;
        }
        .videolist-body>ul>li{
            text-decoration: none;
            padding: 1rem;
            /* padding-top: 0.1rem; */
        }
}
/* @media (min-width:320px) and (max-height:568px){
    .video-join{
        margin-left: 2rem;
    }
}
@media (min-width:390px) and (max-height:844px){
    .video-join{
        margin-left: 7rem;
    }
}
@media only screen and (min-width: 411px) and (max-width: 950px)
{
    .video-join{
        margin-left: 9rem;
    }
} */
@media (min-width: 768px) and (max-width: 1024px) {
  
    .video-join{
        padding: 0 1rem;
        border-radius: 15px;
        border-color: transparent;
        background-color: var(--primary-color);
        color: white;
        height: 2rem;
        /* margin-left: 66%; */
        font-size: 1rem;
    }
    .video-btn{
        padding: 0.5rem 1rem 0.5rem 0.6rem;
        border-radius: 4rem;
        border-color: transparent;
        background-color: var(--primary-color);
        color: white;
        font-size: 1.2rem;
        /* margin-left: 83%; */
        cursor: pointer;
        width: 1.5rem;
        height: 2rem;
        right: 5.5rem;
        /* left: rem; */
        position: fixed;
        display: flex;
        top: 85%;
    }
    .appointment-header {
        border-bottom: 3px solid var(--primary-color);
        margin-bottom: 1rem;
    }
    .card {
        border: none;
        margin-bottom: 1rem !important;
    }
    .videolist-body{
        overflow-y: auto;
    }
}

@media (min-width: 820px) and (max-width: 1024px) {
  
    .video-join{
        padding: 0 1rem;
        border-radius: 15px;
        border-color: transparent;
        background-color: var(--primary-color);
        color: white;
        height: 2rem;
        /* margin-left: 68%; */
        font-size: 1rem;
    }
    .video-btn{
        padding: 0.5rem 1rem 0.5rem 0.6rem;
        border-radius: 4rem;
        border-color: transparent;
        background-color: var(--primary-color);
        color: white;
        font-size: 1.2rem;
        /* margin-left: 83%; */
        cursor: pointer;
        width: 1.5rem;
        height: 2rem;
        right: 5.5rem;
        /* left: rem; */
        position: fixed;
        display: flex;
        top: 85%;
    }
    .appointment-header {
        border-bottom: 3px solid var(--primary-color);
        margin-bottom: 1rem;
    }
    .card {
        border: none;
        margin-bottom: 1rem !important;
    }
    .videolist-body{
        overflow-y: auto;
    }
}


.videoHeader{
    justify-content: space-between;
}





/* videosection */
.ongoingCallDiv_main{
    display: grid;
    margin: 1rem;
    grid-template-columns: 75% 25%;
}
.ongoingCallDiv_video{
    width: 100%;
    height: 100%;
    background: white;
    /* margin: 1rem;
    padding: 1rem; */
    /* padding: 1rem;
    margin: 1rem */
    border: 1px solid;
    flex-direction: column;
    display: flex;
    

}
.ongoingCallDiv_chat{
    /* margin: 1rem; */
    width: 100%;
    height: 76vh;

    /* padding: 0.5rem; */
    /* height: 100%; */
    /* padding: 1rem; */
    border: 1px solid;
    background-color: white;
}

.ongoingCallDiv_videoControlDiv{
    height: 4rem;
    border-top: 1px solid;
}

.ongoingCallDiv_videoDiv{
    height: 100%;
}

.grid-containerVideo {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #2196F3;
    height: 100%;
  }
  .grid-itemVideo {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 50px;
    font-size: 30px;
    text-align: center;
  }


  .videoHeader .meetingHeading{
    border: none;
    /* color: gray; */
    margin: 0 1rem;
    background: none;
    padding: 0.5rem;
    font-size: 19px;
    font-weight: 400;
    color: #828287;
  }

  /* .activeMeeting{
    border-bottom: 1px solid ;
  } */


  .videoContainer{
    height: 100% !important;
  }
.disableVideoJoin{
    background-color:gray
}




@media only screen and (max-width: 1200px) {
  
    .VideoName{
        display: flex;
        justify-content: space-between;
        width:100%;
    }
    .videoNameDiv{
        display: flex
    }

  }
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
  
    .VideoName{
        display: flex;
        justify-content: space-between;
        width:100%;
    }
    .videoNameDiv{
        display: flex
    }

  }


.videoListScroll .notMobile li{
    margin: 1rem;
}

#upcoming{
    /* color:#734BD1; */
    font-weight: bold;
}

a{
    text-decoration: none;
}