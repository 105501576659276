.templates-container{
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
    flex-grow: inherit;
    background: var(--secondary-color) !important;;
}

.templates-header{
    display: flex;
    /* height: 2%; */
    align-items: center;
    /* padding: 1rem 0rem 1rem 2rem; */
    padding: 1rem;
    border: 2px solid #dcd8d840;
    /* display: flex;
    height: 2%;
    align-items: center;
    padding: 2rem;
    border: 2px solid #dcd8d840; */
}
#dropdown-template{
    height: 4rem;
}
.templates-body{
    height: 90%;
    overflow-y: scroll;
}


ul{
    margin: 0;
    width: 100%;
   
    cursor: pointer;
    list-style:none;
}

.templates-body > ul > li{
    text-decoration: none;
    padding: 1.5rem;
    padding-top: 0.5rem;
}

.templatesListItem-div {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    /* align-content: center; */
    /* width: 100%; */
    background: #ffffff;
    /* height: 100%; */
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    padding: 0.8rem 1.5rem;
}

.templatesListItem-div:hover {
    background: var(--secondary-bg-color);
    /* box-shadow: 0px 4px 15px #00000029;
    color: #595959; */
}
.avatar-div{
    display: flex;
    padding: 1rem;
    align-items: center;
}

.list-title{
    display: flex;
    /* justify-content: space-between; */
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.099rem;
    font-weight: bold;
    text-align: center;
    color: var(--primary-color);
    align-items: center;
    padding-top: 1.5rem;
    position: sticky;
    top: 0;
    background: var(--secondary-color) !important;
    z-index: 1;
}
.list-title .templateHeaderItem:nth-child(1) {
    width: 30%;
}
.list-title .templateHeaderItem:nth-child(2) {
    width: 45%;
}
.list-title .templateHeaderItem:nth-child(3) {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    padding-left: 1rem;
}
/* .list-title .templateHeaderItem:nth-child(4) {
    width: 15%;
    display: flex;
    justify-content: flex-end;
} */

select{
    width: 20rem;
    border: none;
    border-radius: 100vw;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: gray;
}

.templatesListItem-hyperlink{
    color: var(--primary-color);
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    /* font-weight: bold; */
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    text-transform: capitalize;
    padding: 0;
    position: relative
}

.templatesListItem-div .temp:nth-child(1){
    width:30%;
}
.templatesListItem-div .temp:nth-child(2){
    width:45%;
}
.templatesListItem-div .temp:nth-child(3){
    width:25%;
    text-align: left;
    padding-left: 1rem;
    /* display: flex;
    justify-content: flex-end; */
}
.template-download{
    margin-left: 0.5rem;
}
/* .templatesListItem-div .temp:nth-child(4){
    width:15%;
    display: flex;
    justify-content: flex-end;
} */

.filterDropdownAssets{
    max-width: 20rem;
  }
  .filterDropdownAssets .genericDropdownDiv{
    min-width: 20rem;
  }


@media only screen and (max-width: 1200px) {
  
    .templates-container{
        
        background: white !important;;
    }
    .templates-body > ul > li{
        padding: 0;
    }

    .templatesListItem-div{
        height: auto;
        box-shadow: none;
        border-bottom: 1px solid lightgray;
        display: block;
    }
    .templatesListItem-div > div:first-child{
        color: var(--primary-color);
    }
    .templatesListItem-div .temp {
        width: 100% !important;
    }
    /* .filterDropdownAssets{
        width: 100%;
        max-width: 100%;
    } */
  
    /* .filterDropdownAssets .genericDropdownDiv{
        min-width: 100%;
      } */

  }
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
  
    .templates-container{
        
        background: white !important;;
    }
    .templates-body > ul > li{
        padding: 0;
    }

    .templatesListItem-div{
        height: auto;
        box-shadow: none;
        border-bottom: 1px solid lightgray;
        display: block;
    }
    .templatesListItem-div > div:first-child{
        color: var(--primary-color);
    }
    .templatesListItem-div .temp {
        width: 100% !important;
    }
    /* .filterDropdownAssets{
        width: 100%;
        max-width: 100%;
    } */
  
    /* .filterDropdownAssets .genericDropdownDiv{
        min-width: 100%;
      } */

  }

  .templates-header .filterDropdownAssets {
    /* margin-top: 1.5rem; */
    padding-right: 3rem;
  }
  .whatsapp{
    border: none !important;
    background: none !important;
    color: var(--primary-color)!important;
  }
.temp-footer{
    font-size: 1.098rem;
}